import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Callout from 'components/callout';
import Header from 'components/header';
import HeroSection from 'components/hero';
import Layout from 'components/layout';
import OfferSection from 'components/offer';
import ScheduleBlock from 'components/mindbody-online';
import PageMeta from 'components/page-meta/page-meta';

import { setLeadId } from 'helpers/leadId';
import { ContentBox, H1Box, BottomImageHr } from 'styles/boxes.css';
import { FocusH1, H1 } from 'styles/headers.css';
import { BoldP } from 'styles/text.css';

const FriendSaleTemplate = ({ data }) => {
  const { lead } = data.gwxapi;
  const friend = lead.friend;
  const campaign = lead.campaign;
  const pageTitle = `${friend.name} Wants You to Become Unstoppable`;
  const pageDescription = `Experience the workout that has changed ${friend.name}'s life. $20 to get started. Your workouts will never be the same again.`;
  const apiUrl = data.site.siteMetadata.apiUrl;
  setLeadId(lead.id, apiUrl);

  return (
    <Layout>
      <PageMeta title={pageTitle} description={pageDescription} />

      <HeroSection>
        <Header bgColor="black" />
        <Callout text="NYC Friends &amp; Family Special" width={1} />
        <H1Box width={1}>
          <H1>Gloveworx NYC</H1>
          <H1>Is Now Open</H1>
          <FocusH1>Book Your Session</FocusH1>
        </H1Box>
        <Callout text="Gloveworx World Trade Center" width={1} />
      </HeroSection>
      {campaign.sessionFocus == 'group' ? (
        <OfferSection
          priceText="Session Price"
          offerPrice="$20"
          normalPrice="$45"
        />
      ) : (
        <OfferSection
          priceText="Private Training Price"
          offerPrice="$99"
          normalPrice="$180"
        />
      )}
      <hr css={BottomImageHr} />
      <ContentBox width={[1, 0.8]} m="auto" p={[3, 4, 5, 5]}>
        <BoldP>
          {friend.name} has given you access to book your first boxing session
          at special opening month discounts. But space is limited. Be one of
          the first in NYC to claim your spot now.
        </BoldP>
        {campaign.mindbodyOnlineWidgetId && (
          <ScheduleBlock
            name="group"
            header="Session Schedule"
            mboID={campaign.mindbodyOnlineWidgetId}
            widgetType={campaign.mboWidgetType}
          />
        )}
      </ContentBox>
      <Callout text="YOUR WORKOUTS WILL NEVER BE THE SAME AGAIN." />
    </Layout>
  );
};

FriendSaleTemplate.propTypes = {
  data: PropTypes.shape({
    siteMetadata: PropTypes.shape({
      apiUrl: PropTypes.string,
    }),
    gwxapi: PropTypes.shape({
      lead: PropTypes.object,
      campaign: PropTypes.object,
    }),
  }),
};

export default FriendSaleTemplate;

export const leadQuery = graphql`
  query($id: String!) {
    gwxapi {
      lead(id: $id) {
        email
        id
        isConverted
        friend {
          id
          name
        }
        campaign {
          id
          mindbodyOnlineWidgetId
          mboWidgetType
          sessionFocus
        }
      }
    }
    site {
      siteMetadata {
        apiUrl
      }
    }
  }
`;
