import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Box } from '@rebass/grid';

import { mq } from 'styles/breakpoints';
import { FocusH1, FocusH3, H2, H3, H4 } from 'styles/headers.css';
import { AbsoluteBox, ImageBox } from 'styles/boxes.css';
import { textColor } from 'styles/text.css';
import { colors } from 'styles/variables.css';

export const OfferH1 = styled(FocusH1)`
  font-size: 5rem;
  ${mq.desktop} {
    font-size: 20rem;
  }
`;

export const OfferH2 = styled(H2)`
  ${textColor.white};
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 1;
  text-decoration-line: line-through;
  text-decoration-color: ${colors.red};

  ${mq.desktop} {
    font-size: 8rem;
  }
`;

export const OfferH3 = styled(H3)`
  margin: 0;
  font-size: 0.9rem;

  ${mq.desktop} {
    font-size: 3rem;
  }
`;

export const OfferH4 = styled(H4)`
  margin: 0;

  ${mq.desktop} {
  }
`;

let primaryTextGrid = css`
  display: grid;
  grid-template-columns: 1fr 4fr 4fr 1fr;
  grid-template-rows: 2fr 2fr 6fr;
  height: 70%;
  text-align: center;
  ${mq.tablet} {
    grid-template-rows: 1fr 2fr 7fr;
  }
`;

let primaryH3Layout = css`
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 2;
`;

let primaryH3 = css`
  margin: 0;
`;

let primaryH1Layout = css`
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 3;
`;

let primaryH4Layout = css`
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;
  margin: 0;
`;

let secondaryTextGrid = css`
  display: grid;
  grid-template-columns: 2rem 8fr 1fr 6fr 1fr 3fr 2rem;
  grid-template-rows: 1rem auto 1rem;
  height: 30%;
  text-align: center;
  ${mq.tablet} {
    grid-template-columns: 1fr 6fr 1fr 4fr 1rem 3fr 1fr;
    grid-template-rows: 2fr 7fr 1fr;
  }
`;

let secondaryH3Layout = css`
  grid-column-start: 4;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 2;
  margin: 0;
  ${mq.tablet} {
    margin-top: 1rem;
  }
`;

let secondaryH2Layout = css`
  grid-column-start: 6;
  grid-column-end: 6;
  grid-row-start: 2;
  grid-row-end: 2;
`;

/*
 * TODO: Abstract the idea of a section that has an image background
 * Combine HeroSection and OfferSection
 */
const OfferSection = ({
  data,
  priceText,
  offerPrice,
  normalPrice,
  children,
  campaignFocus,
}) => {
  // This is to avoid a undefined value check in case we are on a friend campaign
  campaignFocus = typeof campaignFocus == 'undefined' ? 'group' : campaignFocus;

  return (
    <Box
      width={campaignFocus == 'group' ? [1, 0.8] : [1, 1]}
      m="auto"
      style={{ position: 'relative' }}
    >
      <Img
        css={ImageBox}
        fluid={
          campaignFocus == 'group'
            ? data.background.childImageSharp.fluid
            : data.privateSession.childImageSharp.fluid
        }
      />
      <AbsoluteBox>
        {campaignFocus == 'private' ? (
          <React.Fragment>
            <Box css={secondaryTextGrid}>
              <div css={secondaryH3Layout}>
                <OfferH3>Regularly</OfferH3>
              </div>
              <div css={secondaryH2Layout}>
                <OfferH2>{normalPrice}</OfferH2>
              </div>
            </Box>
            <Box css={primaryTextGrid}>
              <div css={primaryH3Layout}>
                <FocusH3 css={primaryH3}>{priceText}</FocusH3>
              </div>
              <OfferH1 css={primaryH1Layout}>{offerPrice}</OfferH1>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box css={primaryTextGrid}>
              <div css={primaryH4Layout}>
                <OfferH4>Leyon Azubuike</OfferH4>
                <OfferH4>Gloveworx Founder</OfferH4>
                <OfferH4>Celebrity Coach</OfferH4>
              </div>
              <div css={primaryH3Layout}>
                <H3 css={primaryH3}>Friends &amp; Family</H3>
                <H3 css={primaryH3}>{priceText}</H3>
              </div>
              <OfferH1 css={primaryH1Layout}>{offerPrice}</OfferH1>
            </Box>
            <Box css={secondaryTextGrid}>
              <div css={secondaryH3Layout}>
                <OfferH3>Regular</OfferH3>
                <OfferH3>{priceText}</OfferH3>
              </div>
              <div css={secondaryH2Layout}>
                <OfferH2>{normalPrice}</OfferH2>
              </div>
            </Box>
          </React.Fragment>
        )}
      </AbsoluteBox>
      {children}
    </Box>
  );
};

/* eslint-disable */
export default props => (
  <StaticQuery
    query={graphql`
      query offer {
        background: file(name: { eq: "gwx-celebration.original" }) {
          childImageSharp {
            fluid(maxWidth: 2000, grayscale: true) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        privateSession: file(name: { eq: "PT-header.original" }) {
          childImageSharp {
            fluid(maxWidth: 2000, grayscale: true) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => <OfferSection data={data} {...props} />}
  />
);

OfferSection.propTypes = {
  data: PropTypes.shape({
    background: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    privateSession: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  priceText: PropTypes.string,
  offerPrice: PropTypes.string,
  normalPrice: PropTypes.string,
  children: PropTypes.node,
  campaignFocus: PropTypes.string,
};

OfferSection.defaultProps = {
  priceText: 'Session Price',
  offerPrice: '$20',
  normalPrice: '$45',
};
